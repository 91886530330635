ThemeJS.BlogLoader = (function ( $, root ) {

    let page = 1;

    $( document ).on( 'click', '.load-more', ( e ) => {

        e.preventDefault();
        page += 1;
        ThemeJS.Functions.send_ajax_request({
            data:{
                'action': 'get_more_blog_articles',
                page:page
            },
            success: ( data ) => {
                let new_articles = data.html;
                $( '.load-more' ).before( new_articles );
                if( data.more == false ){
                    $( '.load-more' ).hide();
                }
            }
        });

    });

})( jQuery, this );