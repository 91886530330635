
$=jQuery;

$(document).ready(function ($) {
  $('.registration_wrapper .gform_next_button').click(function(e){
    $("section").toggleClass("page2");
  });

  $(document).bind('gform_confirmation_loaded', function(e){
    $('.step2').hide();
    $('.page-indicator').hide();
  });
});
