
//MOBILE MENU LOGIC 

ThemeJS.Header_Menu = (function ( $, root ) {
    
    // Initialize Variables
    let  $mobile_menu_container,
         $parent_menu_links,
         $hamburger_button,
         $search_form,
         $search_button,
         $main_container;


    // Get DOM Elements
    function get_dom_el() {
        $mobile_menu_container = $('.header-mobile-content');
        $parent_menu_links = $mobile_menu_container.find('.menu-main-container .menu-item-has-children > a');
        $hamburger_button = $('.hamburger-button');
        $search_form = $( '#search-form' );
        $search_button = $( '.search-button' );
        $main_container = $( 'main' );
    }

    // Functions
    function wrap_anchor_tag() {
        // Wraps anchor tag to parent submenu.
        // Allows the user to click on the 'space' of the parent menu item to toggle the dropdown menu.
        // Clicking on the actual parent menu link anchor tag will take the user to that page. 
        for( let $menu_link of $parent_menu_links ) {
            $($menu_link).attr('href', $($menu_link).attr('href'));
            $($menu_link).addClass('parent-link');
            $($menu_link).wrap('<a href="#" class="dropdown-toggle"><span></span></a>');
        }
    }

    function toggle_submenu() {
        $('.menu-item-has-children').click(function(){
            $(this).find('> a').toggleClass('selected');
            $(this).find('ul').slideToggle();
        })

    }


    /*  
        Hides $element_to_hide if the $clicked_element is not inside a parent container with
        a class name found in class_names.

        Parameters: $element_to_hide: element that will be hidden
                    $class_names: an array of class names that the parent container can contain
                    $clicked_element: the element that was clicked on
    */
    function check_should_hide( $element_to_hide, class_names, $clicked_element ) {
        if ( $element_to_hide.is(':visible') ) {
            while( $clicked_element.length !== 0 ) {
                for( let i=0; i<class_names.length; i++ ) {
                    if( $clicked_element.hasClass( class_names[i] ) ) return;
                }
                $clicked_element = $clicked_element.parent();
            }

            $element_to_hide.slideUp();
            $main_container.removeClass( 'search-overlay' );
        }
    }

    $(function () {

        // Run functions here.
        get_dom_el();

        // Functions to display submenu in mobile mode
        // wrap_anchor_tag();
        // toggle_submenu();

        $hamburger_button.click( function() { 
            $mobile_menu_container.slideToggle();
        } );

        $search_button.click( function(e) {
            e.preventDefault();
            $search_form.slideToggle();
            $main_container.toggleClass( 'search-overlay' );
        } );

        $('body').click( function ( e ) {
            let $target = $(e.target);

            if ( !$target.parent().hasClass("search-button") ) {
                check_should_hide( $search_form, [ 'search-form-wrapper' ], $target );
            }
        });
        
    });

})( jQuery, this );